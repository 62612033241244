import { TBoardMetadata } from '@apb/database/BoardData'
import { Utente } from '@apb/database/schema'
import buildUsersTree, { Node } from '@apb/shared/buildUsersTree'
import classNames from 'classnames'
import { capitalize } from 'lodash'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'

import Semaforo from '../components/Semaforo'
import { Layout } from '../helpers/TreePositionCalc'
import getProgressColor from '../helpers/getProgressColor'
import UtenteOptions from '../components/UtenteOptions'
import { useIsSelf } from '../hooks/useHasRole'
import { formatRole } from '../helpers'

type MinimumUser = Pick<
  Utente,
  'id' | 'nome' | 'cognome' | 'parentId' | 'role' | 'aziendaId' | 'dipartimento' | 'email' | 'emailSent' | 'path'
> & {
  boardMetadata: TBoardMetadata
}
const NODE_SIZE = { width: 180, height: 100 }

const RADIUS = { x: 10, y: 10 }
const STRAIGHT_MARGIN = 0
function pathFunc(
  link: {
    source: { x: number; y: number; height: number; width: number }
    target: { x: number; y: number; height: number; width: number }
  },
  lineHeightBias = 0.5
) {
  const radius = RADIUS
  const { source: s, target: t } = link

  const arX = Math.min(Math.abs(s.x - t.x) / 2, radius.x)
  const arY = Math.min(Math.abs(s.y - s.height / 2 - t.y - t.height / 2) / 2 - STRAIGHT_MARGIN, radius.y)

  const midY = (s.y * (2 * (1 - lineHeightBias)) + s.height / 2 + t.y * (2 * lineHeightBias) - t.height / 2) / 2

  const c11 = { x: s.x, y: t.x > s.x ? midY - arY : midY - arY }
  const c12 = { x: s.x, y: midY }
  const c13 = { x: t.x > s.x ? s.x + arX : s.x - arX, y: midY }
  const c21 = { x: t.x > s.x ? t.x - arX : t.x + arX, y: midY }
  const c22 = { x: t.x, y: midY }
  const c23 = { x: t.x, y: t.x > s.x ? midY + arY : midY + arY }

  let path = `M ${s.x},${s.y}`
  path += `L ${c11.x},${c11.y}`
  path += `Q ${c12.x},${c12.y} ${c13.x},${c13.y}`
  path += `L ${c21.x},${c21.y}`
  path += `Q ${c22.x},${c22.y} ${c23.x},${c23.y}`
  path += `L ${t.x},${t.y}`

  return path
}

function addSizes<T>(node: Node<T>, width: number, height: number): SizedNode<T> {
  return {
    ...node,
    width,
    height,
    children: node.children.map(child => addSizes(child, width, height))
  }
}

export default function TreeView({
  utenti,
  rootUser,
  refetch
}: {
  utenti: MinimumUser[]
  rootUser?: MinimumUser
  refetch: () => void
}) {
  // const { size, translate, containerRef } = useTreeSize()

  const usersTree = useMemo(() => {
    const usersTree = buildUsersTree(utenti)
    const filledUsersTree = {
      ...usersTree,
      data:
        rootUser ??
        ({
          id: -1
        } as MinimumUser)
    }
    return addSizes(filledUsersTree, NODE_SIZE.width, NODE_SIZE.height)
  }, [utenti, rootUser])

  return (
    // <div className="overflow-x-auto">
    <div
      id="treeWrapper"
      // style={{ width: `${size.width + 32}px`, height: `${size.height + 32}px` }}
      className="text-center m-auto bg-gray-50 rounded-xl border border-gray-200 w-fit"
      // ref={containerRef}
    >
      <CustomTree root={usersTree} refetch={refetch} />
    </div>
    // </div>
  )
}

// interface Node<T> {
//   children: Node<T>[]
//   data: T
// }

interface SizedNode<T> extends Node<T> {
  children: SizedNode<T>[]
  width: number
  height: number
  data: T
}
interface PositionedNode<T> extends CollapsableNode<T> {
  x: number
  y: number
  children: PositionedNode<T>[]
}
interface CollapsableNode<T> extends SizedNode<T> {
  collapsed: boolean
  children: CollapsableNode<T>[]
}

function calcPositions<T>({ root }: { root: SizedNode<T> }) {
  const layout = new Layout({
    addBoundingBox: (width, height) => ({ width: width + 20, height: height + 20 }),
    removeBoundingBox: (x, y) => ({ x: x + 20 / 2, y })
  })

  const { result, boundingBox } = layout.layout(root)

  return {
    posRoot: result as PositionedNode<T>,
    boundingBox: boundingBox
  }
}

function linearizeTree<N extends { children: N[] }>(node: N) {
  const out = [node]
  node.children.forEach(child => {
    out.push(...linearizeTree<N>(child))
  })
  return out
}

const PADDING = 20

function usePositionedTree<T extends MinimumUser>(root: SizedNode<T>, collapsedNodes: { [id: number]: boolean }) {
  const collapsedRoot = useMemo(() => {
    const collapsedRoot = {
      ...root,
      children: [],
      collapsed: collapsedNodes[root.data.id]
    } as CollapsableNode<T>

    const travel = (node: SizedNode<T>, collapsedNode: CollapsableNode<T>) => {
      for (const child of node.children) {
        const collapsed = collapsedNodes[child.data.id]

        const newCollapsedNode = { ...child, children: [], collapsed }
        collapsedNode.children.push(newCollapsedNode)

        if (!collapsed) travel(child, newCollapsedNode)
      }
    }

    if (!collapsedNodes[root.data.id]) travel(root, collapsedRoot)

    return collapsedRoot
  }, [root, collapsedNodes])

  const [positions, setPositions] = useState<{
    posRoot: PositionedNode<T>
    boundingBox: { left: number; top: number; right: number; bottom: number }
  }>()
  useEffect(() => {
    if (window) setPositions(calcPositions({ root: collapsedRoot }))
  }, [collapsedRoot])

  return positions
}

function CustomTree({ root, refetch }: { root: SizedNode<MinimumUser>; refetch: () => void }) {
  const [collapsedNodes, setCollapsedNodes] = useState<{ [id: number]: boolean }>({})
  const toggleCollapsedNode = useCallback((id: number) => {
    setCollapsedNodes(prev => ({ ...prev, [id]: !prev[id] }))
  }, [])

  const positions = usePositionedTree(root, collapsedNodes)

  const linear = useMemo(
    () =>
      positions &&
      linearizeTree(positions.posRoot).map(u => ({
        ...u,
        data: { ...u.data, parentId: u.data.parentId === -1 ? null : u.data.parentId }
      })),
    [positions]
  )
  const paths = useMemo(() => {
    if (!linear) return []

    // For each node of the tree
    return linear
      .map(node =>
        node.collapsed
          ? [pathFunc({ source: node, target: { ...node, y: node.y + NODE_SIZE.height / 2 } })]
          : node.children.map(child => pathFunc({ source: node, target: child }, 0.6))
      )
      .flat()
  }, [linear])

  if (!positions || !linear) return null

  return (
    <svg
      className="relative"
      style={{
        width: `${positions.boundingBox.right - positions.boundingBox.left + 2 * PADDING}px`,
        height: `${positions.boundingBox.bottom - positions.boundingBox.top + 2 * PADDING}px`
      }}
      viewBox={`${-NODE_SIZE.width / 2 - PADDING + positions.boundingBox.left} ${
        -NODE_SIZE.height / 2 - PADDING + positions.boundingBox.top
      } ${positions.boundingBox.right - positions.boundingBox.left + 2 * PADDING} ${
        positions.boundingBox.bottom - positions.boundingBox.top + 2 * PADDING
      }`}
    >
      {paths.map((path, i) => (
        <path
          key={i}
          d={path}
          className="tree-custom-path"
          style={{
            stroke: '#888c',
            strokeWidth: 1,
            fill: 'none'
          }}
        />
      ))}
      {linear.map(node => (
        <>
          <foreignObject
            key={node.data.id}
            x={node.x - node.width / 2}
            y={node.y - node.height / 2}
            width={node.width}
            height={node.height}
          >
            <TreeNode node={node} refetch={refetch} />
          </foreignObject>
          {(node.children.length > 0 || collapsedNodes[node.data.id]) && (
            <foreignObject
              key={`${node.data.id}-coll`}
              x={node.x - 8}
              y={node.y + node.height / 2 - 3}
              width={16}
              height={16}
            >
              <div
                className="w-4 h-4 rounded-full border border-gray-300 bg-white cursor-pointer text-gray-400 hover:border-gray-500 hover:text-gray-500 active:text-gray-600 transition"
                onClick={() => {
                  toggleCollapsedNode(node.data.id)
                }}
              >
                <i
                  className={classNames(
                    'fas text-xs text-center w-[.95rem] align-[.34rem] leading-none',
                    collapsedNodes[node.data.id] ? 'fa-plus' : 'fa-minus'
                  )}
                />
              </div>
            </foreignObject>
          )}
        </>
      ))}
    </svg>
  )
}

function TreeNode({ node, refetch }: { node: PositionedNode<MinimumUser>; refetch: () => void }) {
  const router = useRouter()
  const clickable = useMemo(() => node.data.role === 'LINE_MANAGER' || node.data.role === 'MANAGER', [node.data.role])
  const isSelf = useIsSelf(node.data.id)

  return (
    <div className="w-full h-full flex items-center justify-center flex-col">
      <div
        className={classNames(
          'transition m-1 shadow-[0_0_0.25rem_0_#00000020] relative pt-1 pb-2 px-2 bg-white border border-gray-300 rounded-md overflow-clip w-full max-h-full',
          clickable
            ? 'cursor-pointer hover:bg-gray-50 hover:border-primary-dark active:border-primary-darker hover:shadow-[0_0_0.25rem_0_#21c4bf38]'
            : 'hover:border-gray-400'
        )}
        style={
          {
            // Trigger the 3D algorithm to prevent weird Safari bugs
            '-webkit-transform': 'translateZ(0)'
          } as any
        }
        onClick={() => {
          if (clickable) router.push(`/lineManager/${node.data.id}`)
        }}
      >
        {node.data.id !== -1 ? (
          <div className="text-left">
            <p className="font-medium truncate">
              {node.data.nome} {node.data.cognome}
            </p>
            <p className="text-xs leading-none text-gray-500">
              {node.data.dipartimento && <>{capitalize(node.data.dipartimento)} &bull; </>}
              {formatRole(node.data.role)}
            </p>
            <p className="text-xs">
              <span
                onClick={e => {
                  e.stopPropagation()
                  router.push(`/board/${node.data.id}`)
                }}
                className="underline text-gray-500 hover:text-primary active:text-primary-dark cursor-pointer"
              >
                {node.data.boardMetadata ? 'Vai alla Board' : 'Crea la Board'}
              </span>
            </p>
            {node.data.boardMetadata && node.data.boardMetadata.progress !== null && (
              <div className="h-1 bg-gray-200 absolute bottom-0 left-0 right-0">
                <div
                  className="h-full"
                  style={{
                    width: `${node.data.boardMetadata.progress * 100}%`,
                    backgroundColor: getProgressColor(node.data.boardMetadata.progress * 100)
                  }}
                ></div>
              </div>
            )}
            <div className="absolute bottom-0 right-0 h-7 flex items-center">
              {node.data.boardMetadata && node.data.boardMetadata.semaforo !== null && (
                <Semaforo value={node.data.boardMetadata.semaforo} className="inline-block w-3 h-3 mr-2" />
              )}
              {!isSelf && (
                <div className="inline-block -ml-3">
                  <UtenteOptions utente={node.data} mutate={refetch} />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="text-center">
            <p className="text-gray-400 text-xs">
              Direttore generale
              <br />
              non assegnato
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
